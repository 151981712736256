import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { extractSettings } from '../../helpers'
import { useAuth } from '../../hooks'
import { SETTINGS_QUERY } from '../../graphql'
import { Heading, IntlText, Button, Link } from '../../components'

import './style.css'

export function Basic() {

  const { user } = useAuth()
  const settingsQuery = useQuery(SETTINGS_QUERY, {variables: {keys: ["VENDOR_SIGN_UP_EMAIL"]}})
  const { VENDOR_SIGN_UP_EMAIL } = extractSettings(settingsQuery.data?.settings)
  const isActive = user?.contract === "BASIC" && user?.serviceFeeChargeType === "PERIODICAL"

  return(
    <div id="offers" className="container">
      <div className="row padding">
        <Heading left info={<IntlText group="offer" id="intro" />}>
          <IntlText group="offer" id="title" />
          {isActive &&
            <div className='active-package'>
              <FontAwesome name="check-square" />
              <span className='label'><IntlText id="active" /></span>
            </div>
          }
        </Heading>                     
        <div className="offer-block">
          <Heading left info={<IntlText group="offer" id="what-offer-label" />} tag="h4">
            <IntlText group="offer" id="what-offer" />
          </Heading>               
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="globe-americas" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="appearance" /></span>
              <span className="t2"><IntlText group="offer" id="appearance-info" /></span>
            </div>
          </div>
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="motorcycle" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="delivery" /></span>
              <span className="t2"><IntlText group="offer" id="delivery-info" /></span>
            </div>
          </div>
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="credit-card" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="online-payment" /></span>
              <span className="t2"><IntlText group="offer" id="online-payment-info" /></span>
            </div>
          </div>            
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="users-cog" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="software" /></span>
              <span className="t2"><IntlText group="offer" id="software-info" /></span>
            </div>
          </div>
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="badge-percent" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="free-delivery" /></span>
              <span className="t2"><IntlText group="offer" id="free-delivery-info" /></span>
            </div>
          </div>            
          {false && 
            <div className="offer-icon-list">
              <div className="icon">
                <FontAwesome name="utensils-alt" />
              </div>
              <div className="text">
                <span className="t1"><IntlText group="offer" id="digital-menu" /></span>
                <span className="t2"><IntlText group="offer" id="digital-menu-info" /></span>
              </div>
            </div>
          }
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="chart-line" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="report" /></span>
              <span className="t2"><IntlText group="offer" id="report-info" /></span>
            </div>
          </div>
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="user-headset" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="support" /></span>
              <span className="t2"><IntlText group="offer" id="support-info" /></span>
            </div>
          </div>
          <div className="warning">
            <FontAwesome name="exclamation-triangle" />
            <p><IntlText group="offer" id="for-more-information" /> <Link to="/terminos-y-condiciones-afiliado-comercial"><IntlText group="offer" id="terms-of-service" /></Link></p>
          </div>
        </div>
        <div className="offer-block">
          <Heading left info={<IntlText group="offer" id="prices-label" />} tag="h4">
            <IntlText group="offer" id="prices" />
          </Heading>              
          <div className="offer-icon-list">
            <div className="icon">
              <FontAwesome name="dollar-sign" />
            </div>
            <div className="text">
              <span className="t1"><IntlText group="offer" id="service-price" /></span>
              <span className="t2"><IntlText group="offer" id="service-price-info" /></span>
            </div>
          </div>
        </div>         
        <div className="offer-block">
          <Heading left info={<IntlText group="offer" id="how-to-label" />} tag="h4">
            <IntlText group="offer" id="how-to" />
          </Heading>               
        </div>         
        <div className="offer-icon-list">
          <div className="icon">1</div>
          <div className="text">
            <span className="t1"><IntlText group="offer" id="collect-requirements" /></span>
            <span className="t2"><IntlText group="offer" id="collect-requirements-info" /></span>
            <div className='requirements'>
              <ul>
                <li>
                  <span><IntlText group="offer" id="business-information" /></span>
                  <ul>
                    <li><IntlText group="offer" id="brand-name" /></li>
                    <li><IntlText group="offer" id="type" /></li>
                    <li><IntlText group="offer" id="location" /></li>
                    <li><IntlText group="offer" id="logo" /></li>
                    <li><IntlText group="offer" id="email" /></li>
                    <li><IntlText group="offer" id="whats-app" /></li>
                  </ul>                    
                </li>                  
                <li>
                <span><IntlText group="offer" id="legal-information" /></span>
                  <ul>
                    <li>
                      <span><IntlText group="offer" id="for-company" /></span>
                      <ul>
                        <li><IntlText group="offer" id="tax-certificate" /></li>
                        <li><IntlText group="offer" id="identification-representative" /></li>
                        <li><IntlText group="offer" id="incorporation" /></li>
                        <li><IntlText group="offer" id="proof-of-address" /></li>
                      </ul>
                    </li>
                    <li>
                      <span><IntlText group="offer" id="for-moral-person" /></span>
                      <ul>
                        <li><IntlText group="offer" id="tax-certificate" /></li>
                        <li><IntlText group="offer" id="identification" /></li>
                        <li><IntlText group="offer" id="proof-of-address" /></li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>             
          </div>
        </div>
 
        <div className="offer-icon-list">
          <div className="icon">2</div>
          <div className="text">
            <span className="t1"><IntlText group="offer" id="sign-contract" /></span>
            <span className="t2"><IntlText group="offer" id="sign-contract-info" /></span>
          </div>
        </div>

        <div className="offer-icon-list">
          <div className="icon">3</div>
          <div className="text">
            <span className="t1"><IntlText group="offer" id="configure-account" /></span>
            <span className="t2"><IntlText group="offer" id="configure-account-info" /></span>
          </div>
        </div>
 
        <div className="offer-icon-list">
          <div className="icon">4</div>
          <div className="text">
            <span className="t1"><IntlText group="offer" id="ready-to-go" /></span>
            <span className="t2"><IntlText group="offer" id="ready-to-go-info" /></span>
          </div>
        </div>
 
        <div className="offer-block">    
          {isActive ?
            <div className='active-package-placeholder'><FontAwesome name="check-square" /><span><IntlText group="offer" id="how-to-label-active" /></span></div>
          :
            <div className='sign-up'>
              <IntlText group="offer" id="contract-offer" />
              <a href={`mailto:${VENDOR_SIGN_UP_EMAIL?.value}`}> {VENDOR_SIGN_UP_EMAIL?.value}</a>
            </div>
          }
        </div>
        {user?.percentageOfPositive > 0.85 &&
          <div className="offer-block">
            <Heading left info={<IntlText group="offer" id="additional-label" />} tag="h4">
              <IntlText group="offer" id="additional" />
            </Heading>                  
            <Button theme="main"><Link to="/packages/featured"><IntlText group="offer" id="check-featured" /></Link></Button>
          </div>          
        }
      </div>
    </div>
  )
}
